
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { ColumnHeader } from '@/interfaces/components/ColumnHeader';
import VTable from '@/components/shared/table/VTable.vue';
import headers from './headers';
import { Venue } from '@/interfaces/models/Venue';
import { Printer } from '@/interfaces/models/Printer';

@Component({
  components: { VTable },
})
export default class PrinterTable extends Vue {
  @Prop({ type: Object, required: true }) public venue!: Venue;
  @Prop({ type: Array, required: true }) public items!: Printer[];
  @Prop({ type: Boolean, required: false }) public useCustomerGroup!: boolean;
  public headers: ColumnHeader[] = headers;

  private search: string = '';

  get tableHeaders() {
    const tableHeaders: ColumnHeader[] = [];
    if (this.useCustomerGroup) {
      tableHeaders.push({ text: 'printer.table.venue', value: 'venue' });
    }
    tableHeaders.push(...this.headers);

    return tableHeaders;
  }

  public edit(item: Printer) {
    // @ts-ignore
    this.$router.push({ name: 'printer.edit', params: { item, editing: true } });
  }

  @Emit('restart')
  public restart(item: Printer) {
    return {
      venue: this.useCustomerGroup ? item.venueId || '' : this.venue._id,
      printer: item.key,
    };
  }
}
