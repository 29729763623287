
import { Component, Vue, Watch } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import PrinterTable from '@/components/printer/PrinterTable.vue';
import VEmptyState from '@/components/shared/VEmptyState.vue';
import { mixins } from 'vue-class-component';
import CustomerGroup from '@/mixins/CustomerGroup';
import { SelectItem } from '@/interfaces/components/SelectItem';

const venue = namespace('venue');
const printer = namespace('printer');

@Component({
  components: { VEmptyState, PrinterTable, VWrapper },
})
export default class Printer extends mixins(CustomerGroup) {
  @venue.State('active') public venue!: Venue;

  @printer.State('items') public printer!: Printer[];
  @printer.Action('setFilter') public setFilter!: any;
  @printer.Action('fetch') public getPrinter!: any;
  @printer.Action('fetchAll') public getPrinters!: any;
  @printer.Action('restart') public restartPrinter!: any;

  public customerGroup: CustomerGroup | null = null;

  public async reloadData() {
    this.$startLoading('printer');
    if (this.customerGroup !== null) {
      this.setFilter({ group: this.customerGroup });
      await this.getPrinters();
    } else if (this.venue && this.venue._id) {
      this.setFilter({ venue: this.venue._id });
      await this.getPrinter();
    }
    this.$stopLoading('printer');
  }

  public async mounted(): Promise<void> {
    this.reloadData();
  }

  @Watch('venue')
  public async onVenueChange() {
    this.customerGroup = null;
    this.reloadData();
  }

  @Watch('customerGroup')
  public onCustomerGroupChange() {
    this.reloadData();
  }

  public async restart(data: { venue: string; printer: string }) {
    await this.restartPrinter(data);
    this.reloadData();
  }
}
