export default [
  {
    text: 'printer.table.name',
    value: 'name',
  },
  {
    text: 'printer.table.articleCount',
    value: 'articleCount',
  },
  {
    text: 'printer.table.startedAt',
    value: 'started_at',
    type: 'date',
    format: 'YYYY-MM-DD HH:mm',
  },
  {
    text: 'printer.table.lastSignal',
    value: 'lastSignal',
    type: 'slot',
  },
  {
    text: 'printer.table.latestActivity',
    value: 'latestActivity',
    type: 'slot',
  },
];
